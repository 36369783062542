@charset "UTF-8";
/*
Theme Name: STAFF HUB - Tektician
Theme URI: http://tektician.com
Author: Annuar - Digital Project Lead & Team
Author Email: annuar@tektician.com
Description: STAFF HUB App Template by Annuar @ Tektician Sdn Bhd
Version: 1.0.1
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
# Global
    ## 1.1) text

--------------------------------------------------------------*/

/*
**************************
#### Global ####
***** Global (1.1) *****/
:root {
  --main-color: #11A44C;
  --heading-color: #15131C;
  --paragraph-color: #686868;
  --heading-font: "Public Sans", sans-serif !important;
  --body-font: "Public Sans", sans-serif !important;
}
a{ 
  text-decoration: none !important; 
  
}
app-sidemenu li a, ul.list-content li{
  /* color:var(--main-color);  */
  color: var(--paragraph-color);
}
app-sidemenu li a.text-pending{
  color:#ff396f;
}
a.logo.title{
  color:#ffffff;
}
div, b, li{
  font-size: 15.5px;

}
/* buttons */
button, [type=button], [type=reset], [type=submit]{
  border:0px;
}
.contact-form-inner a.btn-large{ margin:0px; cursor:pointer; }

/* Forms */
.signin-area{ min-height:100vh; }
/* You can add global styles to this file, and also import other style files */
/* Tektician Theme */
// @import "~bootstrap/scss/bootstrap";
@import "./theme/responsive.css";
@import "./theme/vendor.css";

// @import "./theme/filter-restyle.css";

// @import "~swiper/scss";

/* Font Awesome */
// @import '../node_modules/font-awesome-animation/font-awesome-animation.scss';
// @import "~@ng-select/ng-select/themes/ant.design.theme.css";
// @import "./theme/style.quickmodal.css";

@import "./theme/style.css";
@import "./theme/style.desktop.css";

/* Global Restyling */
/* Vers. 1; SubVers. 1.0.1 */
.box-footer-note {
  border-top: 1px dashed #eeeeee;
  margin: -15px;
  padding: 15px;
  margin-top: 15px;
  background: #ffffff;
  color: #333333;
}

button,
ion-button {
  border-radius: 5555px !important;
}

.half-modal {
  --max-height: 50% !important;
}

// body::-webkit-scrollbar {
//   width: 15px;
//   border-radius: 50px;
// }

// body::-webkit-scrollbar-track {
//   background: rgb(246, 250, 246);
//   // border: 0.5px solid rgb(170, 170, 170);
//   border-radius: 50px;
//   margin-block: .5em;
// }

// body::-webkit-scrollbar-thumb {
//   background: rgb(129, 252, 129);
//   border-radius: 50px;
// }

// body::-webkit-scrollbar-thumb:hover {
//   background: hsl(120 100% 50% /1);
//   border-radius: 50px;
// }